.contendor_conin{
    width: 100%;
    position: absolute;
    top: 0px;
    background-color: rgba(0,0,0,0.8);
    height: 100%;
    z-index: 80;
    color: white;
}

.contenedor_texto_conin{
    position: absolute;
    top:10%;
    z-index: 82;
    color: white;
    width: 50%;
    left: 5%;
}

.conin{
    position: absolute;
    bottom:0%;
    right: 10%;
    z-index: 81;
    text-align: right;
    width: 30%;
    cursor: pointer;
}

.saltar_conin{
    position: absolute;
    bottom:0%;
    right: 10%;
    z-index: 82;
    text-align: right;
    cursor: pointer;
    font-weight: bold;
    font-size: 16px;
    color: white;
    background-color:black !important;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid black;
    cursor: pointer;
}

.conin_pensando{
    position: absolute;
    bottom:10%;
    right: 10%;
    z-index: 81;
    text-align: right;
    width: 25%;
    cursor: pointer;
    
}

.texto_conin_cerrar{
    position: absolute;
    cursor: pointer;
    top: 10%;
    right: 10%;
    width: 200px;
    z-index: 99;
}


.img_url{
    cursor: pointer;
    width: 100%;
}

.ocultar_iframe{
    visibility: hidden;
}

.mostrar_iframe{
    visibility: visible;
}