img[usemap] {
  border: none;
  height: auto;
  max-width: 100%;
  width: auto;
}
.cintillo_expo{
    background-color: #000000;
    }
    .texto_cintillo_expo{
      color: #ffffff;
      font-size: 46px;
      font-family: 'Montserrat', sans-serif;
      text-align: center;
      font-weight: bold;
      margin: 0px;
    }
    .img_expo_mosaico{
      width: 100% !important;
      height: 100% !important;
      padding: 0px !important;
      margin: 0px !important;
      border: 0px solid black;
    }
    .img_expo_mosaico_principal{
      pointer-events: none;
      width: 100% !important;
      height: 100%;
      padding: 0px !important;
      margin: 0px !important;
      border: 0px solid black;
    }
    .ponter_select{
      cursor: pointer;
      padding: 0px !important;
      margin: 0px !important;
      border: 0px solid black;
    }
    .ponter_select_no{
      cursor:initial;
      padding: 0px !important;
      margin: 0px !important;
      border: 0px solid black;
    }
    
    
    
    .ponter_select_movil_no{
      cursor:initial;
      background-color:green;
    }
    
    .ponter_select_movil{
      background-color:green;
    }
    .img_expo_mosaico_principal_movil{
      width: 100% !important;
    }
    
    
    .img_btns_modal{
      width: 100%;
    }
    .empresa_select_modal{
      width: 100%;
    }
    .linea_modal{
      border: none;
      height: 2px;
      background-color: #ffffff;
    }
    .titulo_modal{
      color:#ffffff;
      margin: 10px 0px 0px 0px;
      padding: 0px;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
    }
    .close_modal_x{
      color:#ffffff;
      margin: 10px 0px 0px 0px;
      padding: 0px;
      font-family: 'Montserrat', sans-serif;
      font-weight: bold;
      font-size: 30px;
      cursor: pointer;
    }
    .contenido_modal{
      font-family: 'Montserrat', sans-serif;
      font-size: 14px;
      color:#ffffff;
      text-align: justify;
      font-weight: bold;
    }
    .logo_select_modal{
      width: 50%;
    }
    .contenedor_video_patrocinador {
        position: relative;
        padding-bottom: 56.25%; /*panorámico*/
        padding-top: 0px;
        height: 0;
    }
    .contenedor_video_patrocinador iframe {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      height: 100%;
      z-index: 19;
    }
    .img_zoom_expo{
      width: 200px;
    }
    .ant-popover{
      z-index: 3!important;
    
    }
    .pop_img_zoom{
      z-index: 3!important;
    }
    .titulo_sobre_expo{
      text-align: left;
      font-weight: bold;
      font-size: 18px;
      color:#163b72;
      margin-bottom: 0px;
    }
    .texto_sobre_expo{
      text-align:justify;
      font-size: 12px;
      color:#163b72;
      text-decoration: none;
    }
    .contendor_info{
      padding: 3% 0%;
    }
    .img_info_expo{
      width: 100%;
    }
    .img_redes_expo{
      width: 40px;
    }
    .siguenos_texto_expo{
      text-align:center;
      font-weight: bold;
      font-size: 24px;
      color:#666666;
    }
    .horario_expo{
      text-align:center;
      font-size: 18px;
      color:#1b54af;
      margin-bottom: 0px;
    }
    .horario_expo_dias{
      text-align:center;
      font-size: 14px;
      color:#666666;
    }
    .texto_resaltado_azul{
      text-align:justify;
      font-size: 14px;
      color:#1b54af;
      font-weight: bold;
    }
    /* SOCIOS MEXICANOS */
    .titulo_azul_socios{
      text-align:center;
      font-weight: bold;
      font-size: 24px;
      color:#1b54af;
    }
    .img_socios_mexicano_expo{
      width: 100%;
    }
    .preguntas_socios{
      text-align:center;
      font-weight: bold;
      font-size: 24px;
      color:#1b54af;
    }
    .panel_socios{
      text-align:justify;
      font-size: 16px;
      color:#000000;
    }
    .texto_pf_socios{
      text-align:justify;
      font-size: 14px;
      color:#666666;
    }
    .texto_patrocinadores_link{
      text-align:justify;
      font-size: 14px;
      color:#666666;
      text-decoration: none;
    }
    .iconos_expo{
      font-size: 30px;
      color:#1b54af!important;
    }
    .text_regresar_stand{
      color:#666666;
      font-size: 18px;
      margin-bottom: 0px;
    }
    .tipo_patrocinador_stand{
      color:#163b72;
      font-size: 24px;
    }
    .btn_info_stand{
      width: 80%;
    }
    .logo_stand{
      width: 160px;
      margin: 4px 0px;
    }
    .logo_info_stand{
      width: 100%;
    }
    @media only screen and (min-width: 1800px) { 
      .logo_stand{
        width: 220px;
        margin: 4px 0px;
      }
      .titulo_sobre_expo{
        font-size: 20px;
      }
      .texto_sobre_expo{
        font-size: 16px;
      }
      .img_zoom_expo{
        width: 350px;
      }
    }
    @media (max-width:997px) {
      .texto_cintillo_expo{
        font-size: 26px;
      }
      .titulo_modal{
        font-size: 26px;
      }
      .close_modal_x{
        font-size: 20px;
      }
      .text_regresar_stand{
        font-size: 14px;
      }
      .tipo_patrocinador_stand{
        font-size: 12px;
      }
    }
    
    .contenedor_expo{
      background: url('../images/evento/lobby_14oct.jpg') no-repeat;
      background-size: 100% 100%;
    }
    .img_opacity_0-{
      opacity: 0;
    }
    .zoom{
      -webkit-transform: scale(1.8); 
      -moz-transform: scale(1.8);
      -o-transform: scale(1.8);
      transform: scale(1.8);
    }
    
    .contenedor_menu{
      width: 100%; 
      height:6em;
      overflow:scroll;
    }