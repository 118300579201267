.contenedor_footer{
  padding-bottom: 10px;
  background-color: #003366;
}
.redes{
  width: 30px;
}
.link_footer{
  text-align: right;
  font-size: 24px;
  font-weight: bold;
  margin: 0px;
}
.titulo_footer{
  text-align: left;
  font-size: 14px;
  margin: 0px;
  color:#ffffff;
  margin-bottom: 0px;
}
.sub_footer{
  text-align: center;
  font-size: 14px;
  margin: 0px;
  color:#ffffff;
  margin-bottom: 0px;
}
.link_redes{
  color: #fbbc16;
}
.link_redes:hover{
  color: #fbbc16;
}
.titulo_redes{
  color: #052c4f;
}
.lofo_footer{
  text-align: left;
}
@media screen and (max-width:767px){
  .redes{
    width: 25px;
  }
  .link_footer{
    text-align: center;
  }
  .titulo_footer{
    text-align: center;
  }
  .lofo_footer{
    text-align: center;
  }
}
@media only screen and (max-width: 500px) {
  
}