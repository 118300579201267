.contenedor_bienvenida{
    background: url(/src/assets/images/premio_2021/back\ _sincamiones.jpg) center no-repeat;
    position: relative;
    padding-bottom: 150px;
}

.camion_izq{
    position: absolute;
    width: 30%;
    z-index: 10;
    bottom: 5%;
    left: 0%;
}

.camion_der{
    position: absolute;
    width: 30%;
    z-index: 10;
    bottom: 5%;
    right: 0%;
}

@media screen and (max-width: 767px){

    .contenedor_bienvenida{
        padding-top: 20px;
        padding-bottom: 0px;
    }

    .camion_der, .camion_izq{
        display: none;
    }
}

@media screen and (min-width: 1200px){

    .contenedor_bienvenida{
        padding-top: 10px;
        padding-bottom: 200px;
    }

    .camion_izq{
        position: absolute;
        width: 30%;
        z-index: 10;
        bottom: 5%;
        left: 0%;
    }
    
    .camion_der{
        position: absolute;
        width: 30%;
        z-index: 10;
        bottom: 5%;
        right: 0%;
    }
}