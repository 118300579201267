
.contenedor_seccion_menu{
  margin-top: 20px;
  border-top: 2px solid #ffffff;
  padding-top: 10px;
  text-align: center;
}
.seccion_inicio_sel_menu{
  position: relative;
  color: #32abdf;
  font-size: 14px;
  font-weight: bold;
}

.seccion_inicio_sel_menu_movil{
  position: relative;
  color: #32abdf;
  font-size: 16px;
  font-weight: bold;
}

.seccion_inicio_sel_menu::before{
  content:url('');
  position:absolute;
  bottom: 50%;
  left: 25%;
}

.seccion_inicio_sel_menu_movil::before{
  content:url('');
  position:absolute;
  bottom: 150%;
  left: 35%;
}

.seccion_inicio_menu{
  position: relative;
  color: white;
  font-size: 14px;
  font-weight: bold;
}


.seccion_sel_menu::before{
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: #fbbc16;
  position:absolute;
  top: -8px;
  left: 45%;
}

.seccion_menu{
  color: white !important;
  font-size: 14px;
  font-weight: bold;
}

.seccion_menu::before{
  content: '';
  display: inline-block;
  width: 10px;
  height: 10px;
  -moz-border-radius: 7.5px;
  -webkit-border-radius: 7.5px;
  border-radius: 7.5px;
  background-color: white;
  position:absolute;
  top: -8px;
  left: 45%;
}


