@font-face {
  font-family: BebasNeue_Bold;
  src: url('../images/sitio/tipografias/BebasNue/BebasNeue_Bold.otf');
}
@font-face {
  font-family: BebasNeue_Book;
  src: url('../images/sitio/tipografias/BebasNue/BebasNeue_Book.otf');
}
@font-face {
  font-family: BebasNeue_Light;
  src: url('../images/sitio/tipografias/BebasNue/BebasNeue_Light.otf');
}
@font-face {
  font-family: BebasNeue_Regular;
  src: url('../images/sitio/tipografias/BebasNue/BebasNeue_Regular.otf');
}
@font-face {
  font-family: BebasNeue_Thin;
  src: url('../images/sitio/tipografias/BebasNue/BebasNeue_Thin.otf');
}
@font-face {
  font-family: Roboto-Black;
  src: url('../images/sitio/tipografias/Roboto/Roboto-Black.ttf');
}
@font-face {
  font-family: Roboto-Bold;
  src: url('../images/sitio/tipografias/Roboto/Roboto-Bold.ttf');
}
@font-face {
  font-family: Roboto-Italic;
  src: url('../images/sitio/tipografias/Roboto/Roboto-Italic.ttf');
}
@font-face {
  font-family: Roboto-Medium;
  src: url('../images/sitio/tipografias/Roboto/Roboto-Medium.ttf');
}
@font-face {
  font-family: Roboto-Regular;
  src: url('../images/sitio/tipografias/Roboto/Roboto-Regular.ttf');
}

body{
  font-family: Arial, sans-serif !important;
}

.color_negro{
  color: #000000;
}
.color_amarillo{
  color: #fbbc16;
}
.back_negro{
  background-color: #000000;
}
.back_amarillo{
  background-color: #fbbc16;
}

.color_azul{
  color: #1141aa;
}
.color_azul_2{
  color: #003366;
}
.color_aqua{
  color:#08c9c9;
}
.color_gris_oscuro{
  color: #58595b !important;
}
.color_gris_claro{
  color:#d5d6d6;
}

.back_azul{
  background-color: #052c4f;
}

.back_azul_menu{
  background-image: linear-gradient(to right, rgba(0,74,250,1), rgba(58,196,255,1));
  
}
.back_gris_oscuro{
  background-color:#58595b;
}

.textos_titulos_secciones{
  font-size: 30px;
  line-height: 30px;
  font-weight: bold;
  padding-top: 3%;
}

.textos_titulos_secciones_no_olvides{
  font-size: 26px;
  line-height: 30px;
  font-weight: bold;
  color: #ffffff;
  background-color: #1141aa;
  text-align: center;
  padding: 5px 0px;
}

.btn_regresar{
  color: #0361c8;
  padding: 5px 10px;
  font-size: 18px;
  margin: 30px 0px;
  cursor: pointer;
  font-weight: bold;
}

.img_100{
  width: 100%;
}
.contenedor_back{
	background-image: url('../images/premio_2022/diplomas/pnsv_web_fondoDiplomaEmpresa.jpg');
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.contenedor_back_diploma{
	background-image: url('../images/premio_2022/diplomas/pnsv_web_fondoDiplomaGanadores.jpg');
	background-attachment: fixed;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
}
.pointer_ver{
  cursor: pointer;
}
.contenedor_video_conferencia {
	position: relative;
	padding-bottom: 56.25%; /*panorámico*/
	padding-top: 0px;
	height: 0;
}
.contenedor_video_conferencia iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
@media screen and (max-width:700px){
  .textos_titulos_secciones{
    font-size: 20px;
    line-height: 20px;
    font-weight: bold;
  }

  .textos_titulos_secciones_no_olvides{
    font-size: 16px;
    line-height: 18px;
  }
}
