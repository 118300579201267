

/** **/
.flip-card {
	perspective: 100%;
	height: 130px;
  }
  
  .flip-card-inner {
	width: 100%;
	height: 100%;
	text-align: center;
	transition: transform 0.9s;
  }
  
  .flip-card:hover .flip-card-inner {
	transform: rotateY(180deg);
  }
  
  .flip-card-front, .flip-card-back {
	width: 100%;
	height: 100%;
	-webkit-backface-visibility: hidden;
	backface-visibility: hidden;
  }
  
  .flip-card-back {
	transform: rotateY(180deg);
  }
  .img_flip{
	  width: 130px;
	  cursor: pointer;
  }
/** **/
.lugar_text{
	color:#052c4f;
	font-size: 14px;
	text-align: center;
	font-weight: bold;
}

.img_empresa_conferencia{
    width: 100%;
	cursor: pointer;
}

.texto_elige{
    font-size: 40px;
    font-weight: bold;
}

/* Gray Scale */
.hover08 figure img {
	-webkit-filter: grayscale(100%);
	filter: grayscale(100%);
	-webkit-transition: .3s ease-in-out;
	transition: .3s ease-in-out;
}
.hover08 figure:hover img {
	-webkit-filter: grayscale(0);
	filter: grayscale(0);
}
