
.contenedor_banner_actividades{
  background-size: 100% 100%;
}

.logo_congreso_actividades{
  width: 100%;
}
.logo_marca{
  width: 80%;
}
.modal_actividades{
  background-color: transparent;
  width: 100%;
}
.modal_videos .ant-modal-content{
  background-color: transparent;
}
.imagen_preview{
  width: 100%;
  cursor: pointer;
}
.close_modal_x{
  font-size: 32px;
  color: #ffffff;
  font-weight: bold;
  text-align: right;
  padding: 5px;
  margin: 0px;
  cursor: pointer;
}
.close_modal_x_img{
  width: 40px;
  height: 40px;
}
.flechas_carousel_activiades{
  color: #fbbc16 !important;
  font-size: 50px;
}
.div_carouse{
  margin: 0px;
  background-color: #000000;
}

.contenedor_video {
  position: relative;
  padding-bottom: 56.25%; /*panorámico*/
  padding-top: 0px;
  height: 0;
}
.contenedor_video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.contenedor_congreso_video{
  padding: 3% 1% 0% 1%;
}
.nombre_video{
  color:#052c4f;
  font-size: 16px;
  text-align: left;
  font-weight: bold;
}
@media only screen and (max-width: 991px) {
  .video{
    height: 400px;
  }
}
@media only screen and (max-width: 600px) {
  .video{
    height: 300px;
  }
}

.titulo_actividades{
  font-size: 26px;
  color: #052c4f;
  font-weight: bold;
  margin-bottom: 0px;
  padding-bottom: 0px;
  margin-top: 20px;
}