.titulo_fecha{
    color: #052c4f;
    font-weight: bold;
    font-size: 26px;
}

.contenedor_body_tabla{
    border-left: 1px solid #aea9b6;
}

.contenedor_header_tabla{
    background-color:  #052c4f;
    color: white;
}

.texto_tabla_hora{
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
    color: white;
    background-color: #052c4f;
    font-weight: bold;
}

.texto_tabla_actividad{
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
    color: white;
    background-color: #aea9b6;
}

.texto_tabla_body_hora{
    text-align: left;
    padding-left: 15px;
    padding-right: 5px;
    color: #052c4f;
    font-weight: bold;
    position: relative;
}

.texto_tabla_body_hora::before{
    content: '';
    display: inline-block;
    width: 10px;
    height: 10px;
    -moz-border-radius: 7.5px;
    -webkit-border-radius: 7.5px;
    border-radius: 7.5px;
    background-color: #052c4f;
    position: absolute;
    left: -5px;
    top:25%;
 }

.texto_tabla_body_actividad{
    text-align: left;
    padding-left: 5px;
    padding-right: 5px;
    color: #052c4f;
}

.contendor_banner{
    background-image: url('../images/registro/back_registro_pnsvXXI_.jpg');
    background-size: cover;
    background-position: center; 
  background-repeat: no-repeat;
    height: 100%;
    width: 100%;
  }

.img_logo_usuarios_agenda{
    width:200px;
}

.img_bienvenida_agenda{
    width:400px;
}

.texto_agenda_banner{
    color: #f7af21;
    font-size: 20px;
    font-weight: bold;
}

@media only screen and (max-width: 700px) {
    .contendor_banner{
        background-image: url('../images/registro/pnsvXXI_imgn_movil.jpg');
        background-size: cover;
        background-position: center; 
      background-repeat: no-repeat;
        height: 100%;
        width: 100%;
      }
}