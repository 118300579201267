.subtitle{
  font-size: 18px;
  font-weight: bold;
  color: #0062ac !important;
}

.button-login{
  color: white;
  background-color: #097ff6;
  border: 0;
  padding: 5px 50px;
  border-radius: 30px;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
}

.button-login:hover{
  background-color: #003367;
}

.button-login:disabled{
  opacity: 0.7;
  cursor: not-allowed;

}

.contenedor_banner_registro_exitoso{
  background: url('../images/premio_2021/back_regsitro_pnsv.jpg');
  background-size: 100% 1000px;
  width: 100%;
}

.fondo_form{
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 30;
}

.img_premio{
  width: 250px;
  margin-top: 5px;
}

.img_bienvenidos{
  width: 300px;
}

.nom-label-input{
  font-weight: bold;
  color: #1c3661   !important;
}

.texto_radios{
  color:white;
}

.select_entrada{
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 3px solid #097ff7;
  width: 100%;
  padding: 5px;
  color:#003367;
  padding: 5px 5px;
}

.entrada_input{
  /* background-color: rgba(0,0,0,0);
  border-bottom: 2px solid white;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  width: 100%;
  color:white; */
  background-color: white;
  border-top: 0px;
  border-bottom: 0px;
  border-right: 0px;
  border-left: 3px solid #097ff7;
  width: 100%;
  color:#003367;
  padding: 5px 5px;
}

.fondo_registro{
  background-image: url('../images/premio_2022/registro/pnsv_web_InicioFondo.jpg');
  background-size: 100% 100%;
  height: 700px;
}

.contenedor_contacto_registro_exitoso{
  background-color: #fbbc16;
  padding-top: 5px;
  padding-bottom: 5px;
}

.necesitas_ayuda_registro_exitoso{
  font-size: 30px;
  color:black;
  font-weight: bold;
  text-align: center;
  margin-bottom: 0px;
}

.img_contacto_registro_exitoso{
  width: 40px;
}

.intrucciones_registro_exitoso{
  font-size: 20px;
  font-weight: bold;
  color: black;
  text-align: left;
}

.contacto_registro_exitoso{
  text-align: left;
  font-size: 16px;
  color: black;
  margin-bottom: 0px;
}

.titulo-registrar{
  text-align: center;
  font-size: 45px;
  letter-spacing: 3px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
@media only screen and (max-width: 500px) {
  .titulo-registrar{
  font-size: 24px;
  }
}
.titulo-completo{
  text-align: center;
  font-size: 45px;
  color: #01356f;
  letter-spacing: 3px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-weight: bold;
}
.texto-breve{
  font-size: 18px;
  text-align: left;
}
.correo-azul{
  font-size: 24px;
  text-align: left;
}
.texto-azul{
  color:#414042;
  font-size: 22px;
  text-align: left;
}
.datos-azul{
  font-size: 16px;
  text-align: left;
}
.leyenda{
  font-size: 16px;
  text-align: left;
}
.fondo{
  font-size: 24px;
  padding: 5px;
}
.nota{
  font-size: 14px;
  text-align: center;
  color: black;
}

.texto_inicio_registro{
  font-size: 20px;
  text-align: center;
}

.texto_inicio_registro_hash{
  font-size: 30px;
  text-align: center;
}

.texto_recepcion_virtual{
  text-align: center;
  font-size: 30px;
  color:white;
  padding-left: 15px;
  padding-right: 15px;
}

.img_registrar_re_master{
  width: 400px;
}

.texto_titulo_instruccions{
  font-size: 24px;
  text-align: left;
  font-weight: bold;
}

.contenedor_datos_info_registro_exitoso{
  text-align: left;
}

.img_acceder_transmision_registroso_exitoso{
  width: 100%;
  margin-bottom: 0px;
}

.acceder_transmision_registroso_exitoso{
  margin-bottom: 0px;
  color:black;
}

.contacto_pago{
  text-align:justify;
  font-size:18px;
}

.img_paypal_pago{
  width: 100px;
}

@media screen and (max-width:767px){
  .texto_inicio_registro{
    font-size: 12px;
    text-align: center;
  }

  .texto_inicio_registro_hash{
    font-size: 18px;
    text-align: center;
  }

  .correo-azul{
    font-size: 12px;
    text-align: center;
  }

  .texto_recepcion_virtual{
    text-align: center;
    font-size: 12px;
    color:white;
    padding-right: 10px;
    padding-left: 10px;
  }

  .img_registrar_re_master{
    width: 250px;
  }

  .texto-breve{
    font-size: 12px;
    text-align: center;
  }

  .leyenda{
    font-size: 12px;
    text-align: center;
  }

  .texto_titulo_instruccions{
    font-size: 14px;
  }

  .datos-azul{
    font-size: 12px;
    text-align: center;
  }

  .nota{
    font-size: 10px;
    text-align: center;
    color: black;
  }

  .contacto_pago{
    text-align:left;
    font-size:12px;
  }

  .img_paypal_pago{
    width: 60px;
  }

  .fondo_registro{
    background-image: url('../images/premio_2021/back_regsitro_pnsv.jpg');
    
  }
  .img_premio{
    width: 250px;
    margin-top: 10px;
  }
}

.texto_span_inicio_registro{
  color: #01c7e2;
}

.existe_cuenta_texto{
  text-align: center;
}

.barra_azul_registro{
  border: 1px solid #1141aa;
  background-color: #1141aa;
}


