.cabeza_operador_gris{
  background-color: #aea9b6;
  color: #ffffff;
  font-size: 24px;
  text-align: center;
  font-weight: bold;
  padding: 5px 0px;
  font-family: Roboto-Regular;
}
.cabeza_operador_azul{
  color: #052c4f;
  font-size: 24px;
  text-align: left;
  font-weight: bold;
  font-family: Roboto-Regular;
}
.secciones_operados_azul{
  color: #003366;
  font-size: 20px;
  text-align: left;
  font-weight: bold;
  padding: 5px 0px;
  font-family: Roboto-Regular;
}
.nombre_operador{
  color: #003366;
}
.datos_operador{
  color:#0261c8;
}
.td_operador{
  padding-left: 2%;
  padding-bottom: 5px;
  font-family: Roboto-Regular;
}
.tr_select_operador{
  font-family: Roboto-Regular;
  color:#575756;
  text-align: left;
}
.tr_select_operador:hover{
  background-color: #f1f2f2;
  font-family: Roboto-Regular;
  color:#ffffff !important;
}

.tr_select_operador:hover a{
  background-color: #052c4f;
  font-family: Roboto-Regular;
  color:#ffffff !important;
}

.link_operador{
  color: #ffffff;
  font-weight: bold;
  text-decoration: none;
}

.texto_buscar{
  font-size: 20px;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.input_buscar_premio{
  width: 95%;
  border-radius: 0px;
  border: none;
  font-size: 20px;
  background-color: #f1f2f2;
  color:#003366;
}
.search_icon{
  background-color: #0261c8;
  color:#ffffff;
  width: 100%;
  font-size: 20px;
  padding: 0.9%;
}
@media only screen and (max-width: 700px) {
  .cabeza_operador_gris{
    font-size: 18px;
  }
  .secciones_operados_azul{
    font-size: 14px;
  }
  .td_operador{
    font-size: 14px;
  }
  .tr_select_operador{
    font-size: 14px;
  }
  .texto_buscar{
    font-size: 14px;
  }
  
}

@media only screen and (max-width: 570px) {
  .cabeza_operador_gris{
    font-size: 18px;
  }
  .secciones_operados_azul{
    font-size: 12px;
  }
  .td_operador{
    font-size: 12px;
  }
  .tr_select_operador{
    font-size: 12px;
  }
  .texto_buscar{
    font-size: 14px;
  }
  
}