.contenedor_contenido{
    position: relative;
}

.contenedor_conin{
    position: absolute;
    height: 100%;
}

.conin{
    position: absolute;
    bottom: 0%;
    right: 10%;
}


